import { isArray } from 'lodash'
export default {
    computed: {
        inputPlaceholder() {
            if(this.onlySearch) {
                return 'Поиск'
            }
            return this.tags.length ? '+ поиск' : 'Фильтр + поиск'
        },
        listColumn() {
            if (this.filterInclude.length > 4) {
                if (this.filterInclude.length >= 10)
                    return 'grid-cols-3 gap-3'
                else
                    return 'grid-cols-2 gap-4'
            } else
                return 'gap-3'
        },


        filterPopupWidth() {
            if (this.width) {
                return this.width
            } else if (this.filterInclude.length > 4)
                return this.windowWidth > 1436 ? '1050px' : '750px'
            else
                return '470px'
        },


        user() {
            return this.$store.state.user.user
        },


        filterTagsLength() {
            let count = 0
            for (let prop in this.filterTags) {
                if (isArray(this.filterTags[prop])) {
                    if (this.filterTags[prop].length)
                        count = count + 1
                } else {
                    if (this.filterTags[prop])
                        count = count + 1
                }
            }
            return count
        },


        checkLoaded() {
            if (this.$store.state.filter.filter[this.name] !== undefined)
                return true
            else
                return false

        },

        checkExclude() {
            return this.filterExclude.length > 0
        },


        filterStatus() {
            return this.$store.state.filter.filterStatus[this.name]
        },


        selected() {
            return this.$store.state.filter.filterSelected[this.name]
        },

        showSearchInput() {
            return this.$store.state.filter.filterShowSearch?.[this.name] ? true : false
        },

        filtersSearch() {
            return this.$store.state.filter.filtersSearch?.[this.name] ? this.$store.state.filter.filtersSearch[this.name] : ''
        },

        activeFilters() {
            return this.$store.state.filter.filterActive[this.name]
        },

        disabledClearBtn() {
            if ((this.includeLenght + this.excludeLenght) === 0) return true
            return false
        },

        includeLenght() {
            return this.generateLength(false)
        },


        excludeLenght() {
            return this.generateLength(true)
        },


        filterTags() {
            return this.$store.state.filter.filterTags[this.name]
        },


        windowWidth() {
            return this.$store.state.windowWidth
        },

        tagsViewFilter() {
            let array = [],
                other = [],
                max = 1;

            if(this.windowWidth < 1356)
                max = 0 

            if (this.tags.length) {
                this.tags.forEach((tag, index) => {
                    if(this.windowWidth < 1068) {
                        other.push(tag)
                    } else {
                        if (index <= max) {
                            array.push(tag)
                        } else {
                            other.push(tag)
                        }
                    }
                })
            }
            if (other.length)
                array.push({ type: 'other', value: other })

            return array
        }
    }
}